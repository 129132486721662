var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("对话列表")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v("聊天窗口")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }